.label {
  @apply relative;

  .preview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
}
