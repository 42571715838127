.trashContanier {
  @apply border;
  @apply border-blue;
  @apply bg-white;
  @apply text-blue;
  @apply hover:bg-blue;
  @apply hover:text-white;
}

.trashSvg {
  @apply fill-current;
}
