.label {
  @apply relative;
  @apply cursor-pointer;
  &Text {
    @apply absolute;
    bottom: 14px;
    width: 100%;
    span {
      @apply text-sm;
      @apply text-white;
      line-height: 14px;
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .preview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
}
