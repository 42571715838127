.paginationItemContanier {
  @apply rounded;
  @apply h-10;
  @apply w-12;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-blue;
}

.selected {
  @apply text-white;
  @apply bg-blue;
}

.paginationItemContanier:hover {
  @apply bg-blue-active;
  @apply text-white;
}

.paginationItemContanier:active {
  @apply border;
  @apply border-blue;
  @apply text-blue;
  @apply bg-white;
}
