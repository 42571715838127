@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  @apply text-black;
  @apply font-medium;
  @apply text-base;
  height: 100%;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
