.label {
  @apply focus:outline-none;
  letter-spacing: 1px;
  border-radius: 3px;
  @apply text-base;
  @apply bg-blue;
  @apply text-white;
  @apply cursor-pointer;
}
.label:hover {
  @apply border;
  @apply bg-white;
  @apply border-blue;
  @apply text-blue;
}
