.btnDetail {
  .iconActive {
    display: none;
  }
  .icon {
    display: block;
  }
  &:hover {
    .iconActive {
      display: block;
    }
    .icon {
      display: none;
    }
  }
}

.arrowIconActive {
  transform: rotate(180deg);
}
