.tr {
  @apply h-16;
  @apply text-base;
}

.td {
  @apply border-dark-gray;
  @apply pl-4;
  @apply pr-4;
}

.tdCode {
  font-family: "Noto Sans JP";
  @apply font-bold;
}

.tdName {
  @apply pr-2;
}

.tdComments {
  @apply pl-1;
  @apply pr-1;
  @apply border-dark-gray;
}

.tooltipArrow {
  @apply border-solid;
  @apply border;
  @apply border-gray-500;
  border-top: 0px;
  border-left: 0px;
}

.tdButtons {
  @apply flex;
  @apply flex-row;
  @apply pt-4;
}
