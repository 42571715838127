.label {
  @apply w-96;
  display: block;
  &Text {
    display: block;

    @apply cursor-pointer;
    @apply border;
    @apply border-blue;
    @apply text-center;
    @apply text-blue;
    @apply w-96;
    @apply h-10;
    @apply leading-10;
    @apply rounded;

    bottom: 14px;
  }
}
.fileName {
  margin-top: 10px;
  @apply relative;
  @apply bg-light-blue;
  border-radius: 3px;
  display: inline-block;
  padding: 6px 36px 6px 12px;
  min-width: 384px;
}

.errFileName {
  margin-top: 10px;
  @apply relative;
  border-radius: 3px;
  display: inline-block;
  padding: 6px 36px 6px 12px;
  min-width: 384px;
}

.deleteIcon {
  @apply absolute;
  top: calc(50% - 5.5px);
  right: 12px;
  cursor: pointer;
}
