.wrap {
  position: relative;
  .wrapBody {
    position: absolute;
  }
  .wrapBodyActive {
    position: absolute;
    z-index: 999;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
}
