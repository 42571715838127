.container {
  @apply flex;
  flex-direction: column;
  align-items: center;
  width: 681px;
  height: 180px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  @apply border-blue;
  border-style: dashed;
  @apply text-black;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-left: auto;
  margin-right: auto;

  .isDragAccept {
    border-color: #00e676;
  }
  .isDragActive {
    border-color: #2196f3;
  }
  .isDragReject {
    border-color: #ff1744;
  }
}

.containerInActive {
  @apply flex;
  flex-direction: column;
  align-items: center;
  width: 685px;
  height: 180px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  @apply border-gray-light;
  border-style: dashed;
  @apply text-gray-dark;
  background-color: #f1f1f1;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-left: auto;
  margin-right: auto;

  .isDragAccept {
    border-color: #00e676;
  }
  .isDragActive {
    border-color: #2196f3;
  }
  .isDragReject {
    border-color: #ff1744;
  }
}

.label {
  @apply relative;
  width: 214px;
  height: 132px;
  background-repeat: no-repeat;
  @apply mb-2;
  &Text {
    @apply absolute;
    @apply cursor-pointer;
    background-color: #000000;
    @apply text-white;
    border: 1px solid;
    top: 0px;
    width: 23px;
    @apply right-0;
    margin-bottom: 1em;
    span {
      line-height: 20px;
      letter-spacing: 0.05px;
      display: block;
      width: 100%;
      text-align: center;
      small {
        font-size: 8px;
      }
    }
    .textGray {
      color: #a5a5a5;
    }
  }
  .preview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  .previewBox {
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.5),
      0 2px 4px -1px rgba(0, 0, 0, 0.3);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .errorBox {
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.5),
      0 2px 4px -1px rgba(0, 0, 0, 0.3);
    width: 100%;
    color: #ea8080;
    @apply border;
    @apply border-error;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.previewContainer {
  width: 685px;
  margin-left: auto;
  margin-right: auto;
}
