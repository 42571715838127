.loadingWrapper {
  position: fixed;
  z-index: 9;
  left: 90px;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
}
.loading {
  position: absolute;
  top: 240px;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply w-80;
  @apply text-center;
  @apply mx-auto;
  img {
    width: 155px;
    display: block;
    margin: 24px auto;
  }
}
