.cell {
  border: 1px rgba(151, 151, 151) solid;
  width: 96px;
  height: 40px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  justify-content: center;
}
