.base {
  @apply rounded;
  width: 598px;
  @apply border-2;
  background-color: #e9f7ff;
  border-color: #0091dc;
  color: #195796;
}

.textBox {
  width: 490px;
  @apply text-black;
  @apply h-9;
  @apply align-bottom;
}

.deleteIcon {
  top: calc(50% - 5.5px);
  right: 12px;
  cursor: pointer;
  @apply flex;
  @apply align-top;
}
