.arrow {
  @apply relative;
  background-image: url("../downArrow.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
}

select:disabled {
  @apply bg-gray-light;
}
