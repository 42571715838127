.check {
  @apply relative;
  @apply w-5;
  @apply h-5;
  @apply border;
  @apply border-2;
  @apply rounded-full;
  @apply border-dark-gray;
}

.check:checked {
  @apply border-blue;
}

.check:checked:before {
  @apply absolute;
  content: "";
  top: 0.21428571428rem; /* 3px when base 14px */
  left: 0.21428571428rem; /* 3px when base 14px */
  @apply w-2.5;
  @apply h-2.5;
  @apply rounded-full;
  @apply bg-blue;
}
