.switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 20px;
  max-width: 100%;
  @apply bg-dark-gray;
  border-radius: 100em;
}
.switch::before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 100%;
  background: white;
  border-radius: 100%;
  box-shadow: -2px 2px 2px 0 #bbbbbb, 0px 0px 10px 0 #e4e4e4;
  transition: all 0.2s;
}
.switch.on {
  background: #0091dc;
  box-shadow: unset;
}
.switch.on::before {
  margin-left: 50%;
  box-shadow: 2px 2px 2px 0 #bbbbbb, 0px 0px 10px 0 #e4e4e4;
}
