.editPenButton {
  width: 18px;
  height: 18px;
  background-image: url("./pen.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.editPenButton:active {
  background-image: url("./pen_click.svg");
}

.editPenButton:active:hover {
  background-image: url("./pen_click.svg");
}

.editPenButton:hover {
  background-image: url("./pen_hover.svg");
}
