.arrowContanier {
  @apply border;
  @apply border-blue;
  @apply rounded;
  @apply h-10;
  @apply w-12;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply text-blue;
  @apply hover:bg-blue;
  @apply hover:text-white;
  @apply active:border-blue-active;
  @apply active:text-blue-active;
  @apply active:bg-white;
}

.arrowContanier:disabled {
  @apply border;
  border-color: #bfbfbf;
  color: #bfbfbf;
  @apply bg-white;
  @apply cursor-default;
}

.arrowSvg {
  @apply fill-current;
}
