.label {
  @apply relative;
  @apply cursor-pointer;
  width: 214px;
  height: 132px;
  background-repeat: no-repeat;
  @apply mb-2;
  &Text {
    @apply absolute;
    border: 1px solid;
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.5),
      0 2px 4px -1px rgba(0, 0, 0, 0.3);
    top: 0px;
    width: 100%;
    padding: 12px;
    margin-bottom: 1em;
    span {
      line-height: 20px;
      letter-spacing: 0.05px;
      display: block;
      width: 100%;
      text-align: center;
      small {
        font-size: 8px;
      }
    }
    .textGray {
      color: #a5a5a5;
    }
  }

  .preview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  .previewBox {
    box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.5),
      0 2px 4px -1px rgba(0, 0, 0, 0.3);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.btnWrap {
  @apply text-gray-dark;
  @apply flex;
  @apply justify-between;

  span {
    font-size: 14px;
    letter-spacing: 0.081px;
    padding: 0 14px;
    border: 1px solid#A5A5A5;
    border-radius: 4px;
    @apply mt-3;
  }
}

.btnWrapActive {
  span {
    @apply border-blue;
    @apply text-blue;
  }
}
