.questionnaireWidth {
  width: 655px;
}

.charcterLength {
  @apply text-xs;
  @apply text-gray-400;
  @apply align-bottom;
}

.overColor {
  color: #f0371c;
}
