.boxShadow {
  box-shadow: 0px 2px 4px 0px #979797;
}

.saleError {
  @apply relative;
  .errorText {
    @apply absolute;
    @apply items-center;
    @apply hidden;
    @apply p-2;
    @apply text-red;
    @apply w-72;
    @apply bg-white;
    @apply border;
    @apply border-red;
    @apply rounded-sm;
    @apply -top-11;
    @apply -left-1;
    box-shadow: 0px 2px 4px 0px #979797;
  }
  &:hover {
    .errorText {
      @apply block;
    }
  }
}
