.box {
  font-family: "Noto Sans JP";
  width: 440px;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  @apply text-base;
}

.title {
  letter-spacing: 0.01px;
  color: rgba(25, 87, 150, 1);
  @apply font-bold;
  @apply text-lg;
}

.titleLine {
  width: 400px;
  height: 2px;
  background-color: rgba(132, 166, 195, 1);
}

.message {
  letter-spacing: 0.01px;
  color: rgba(52, 73, 94, 1);
}

.cancelButton {
  width: 120px;
  height: 50px;
}

.okButton {
  width: 120px;
  height: 50px;
}
