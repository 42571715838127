.button {
  border-radius: 3px;
  @apply h-8;
  @apply mr-2;
  @apply pr-3;
  width: 76px;
}

.pen {
  @apply border;
  @apply border-blue;
  @apply text-blue;
}

.pen:hover {
  @apply bg-blue;
  @apply text-white;
}

.pen:active {
  @apply bg-white;
  @apply border-blue-active;
  @apply text-blue-active;
}

.trash {
  color: #979797;
  border-color: #979797;
  @apply border;
  @apply bg-white;
}

.trash:hover {
  @apply bg-blue;
  @apply text-white;
  @apply border-blue;
}

.trash:active {
  @apply bg-white;
  @apply border-blue-active;
  @apply text-blue-active;
}
