.page {
  font-family: "NotoSansJP";
  @apply font-medium;
}

.confirmAnnotation {
  letter-spacing: 0.01px;
  color: rgba(52, 73, 94, 1);
  @apply text-base;
}

.confirmLable {
  letter-spacing: 0.01px;
  color: rgba(25, 87, 150, 1);
  @apply text-lg;
}

.confirmValue {
  letter-spacing: 0.01px;
  color: rgba(52, 73, 94, 1);
  @apply text-base;
}
