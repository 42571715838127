.titleColor {
  color: #006ea3;
}

.labelMaxMinColor {
  color: #979797;
  @apply align-text-top;
  @apply pt-5;
}

.freeTextField {
  @apply bg-blue-50;
  @apply border-gray-300;
  @apply border-l;
  @apply border-r-2;
  @apply border-t-2;
  @apply h-8;
  @apply w-80;
  @apply rounded;
}
