.table {
  @apply border;
  @apply border-collapse;
  @apply border-dark-gray;
  font-family: "Noto Sans JP";
}

.thead {
  @apply bg-subtitle;
  @apply text-left;
}

.tr {
  @apply h-12;
  @apply text-white;
  @apply text-base;
}

.trCode {
  min-width: 144px;
  @apply pl-4;
  @apply font-normal;
}

.trName {
  min-width: 128px;
  @apply pl-4;
  @apply font-normal;
}

.trComment {
  @apply pl-4;
  @apply font-normal;
}

.trStartDate {
  min-width: 144px;
  @apply pl-4;
  @apply font-normal;
}

.trEndDate {
  min-width: 144px;
  @apply pl-4;
  @apply font-normal;
}

.trPrice {
  min-width: 102px;
  @apply pl-4;
  @apply font-normal;
}

.trDurationMonths {
  min-width: 102px;
  @apply pl-4;
  @apply font-normal;
}

.trButtons {
  width: 248px;
  @apply pl-4;
  @apply font-normal;
}
