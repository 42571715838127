.page {
  font-family: "NotoSansJP";
  @apply font-medium;
}

.formLabel {
  @apply text-lg;
}

.formInput {
  @apply text-lg;
  letter-spacing: 0.01px;
}

.labelAnnotationBox {
  background: rgba(52, 73, 94, 0.05) 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

.labelAnnotationText {
  color: #34495e;
  opacity: 0.6;
  @apply text-sm;
  @apply mt-1;
  @apply mb-1;
  @apply ml-2;
  @apply mr-2;
}

.inputAnnotationBox {
  @apply mt-2;
}

.inputAnnoutationText {
  color: rgba(52, 73, 94, 0.6);
}

.zeinukiLabel {
  font: normal normal bold 20px/36px "Noto Sans JP";
  letter-spacing: 0.02px;
  color: rgba(51, 51, 51, 0.8);
}

.descriptionTextArea {
  @apply pt-3;
  @apply pb-3;
  @apply pl-4;
  @apply pr-4;
}
