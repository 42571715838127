.container {
  @apply w-full;
  min-height: 100%;
  @apply min-h-full;
  @apply border;
  border-radius: 3px;
  @apply border-collapse;
  @apply border-dark-gray;
}

.listGrades {
  @apply flex;
  @apply gap-2;
  @apply overflow-x-scroll;
  .itemGrades {
    width: 76px;
    flex-shrink: 0;
    text-align: center;
    .label {
      @apply bg-light-blue;
      @apply text-center;
      @apply text-sm;
      @apply p-1;
      @apply rounded;
      @apply block;
      width: 76px;
    }
    .gradesCount {
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.02px;
      font-weight: bold;
    }
  }
}
