.fileName {
  margin-top: 10px;
  @apply relative;
  @apply bg-light-blue;
  border-radius: 3px;
  display: inline-block;
  padding: 6px 36px 6px 12px;
  min-width: 384px;
}
.deleteIcon {
  @apply absolute;
  top: calc(50% - 5.5px);
  right: 12px;
  cursor: pointer;
}
