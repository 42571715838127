.container {
  width: 400px;
  @apply min-h-full;
  @apply mx-2;
  @apply inline-block;
  @apply align-top;
  @apply border;
  border-radius: 3px;
  @apply border-collapse;
  @apply border-dark-gray;
}

.title {
  @apply inline-block;
  @apply whitespace-nowrap;
  @apply overflow-ellipsis;
  @apply overflow-hidden;
  &Course {
    @apply text-xl;
    width: 11ch;
  }
  &Class {
    width: 16ch;
  }
  &Lesson {
    width: 24ch;
  }
}

.btnDownload {
  .iconActive {
    display: none;
  }
  .icon {
    display: block;
  }
  &:hover {
    .iconActive {
      display: block;
    }
    .icon {
      display: none;
    }
  }
}

.tooltipContainer {
  @apply relative;
  .tooltip {
    @apply absolute;
    @apply items-center;
    @apply hidden;
    @apply p-2;
    @apply text-white;
    @apply w-56;
    background: #4d4d4d;
    @apply border;
    @apply rounded;
    @apply -top-12;
  }
  &:hover {
    .tooltip {
      @apply block;
      &:after {
        @apply absolute;
        border: solid transparent;
        content: "";
        @apply w-0;
        @apply h-0;
        @apply pointer-events-none;
        border-top-width: 10px;
        border-bottom-width: 10px;
        border-left-width: 7px;
        border-right-width: 7px;
        margin-left: -7px;
        border-top-color: #4d4d4d;
        @apply left-1/2;
        @apply top-full;
      }
    }
  }
}

.rounded20 {
  @apply focus:outline-none;
  letter-spacing: 1px;
  border-radius: 20px;
  @apply text-base;
}
