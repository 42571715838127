.item {
  @apply border-t;
}

.item:last-child {
  @apply border-b;
}

.openedMainItem {
  @apply text-base-blue;
}

.closedMainItem {
  @apply text-black;
}

.mainItem {
  @apply inline-flex;
  @apply items-center;
  @apply text-lg;
  @apply h-12;
  @apply w-full;
  @apply pl-2.5;
  @apply pr-4;
  @apply border-b;
  @apply border-base-blue;
}

.subItem {
  @apply inline-flex;
  @apply items-center;
  @apply justify-between;
  @apply text-white;
  @apply text-sm;
  @apply h-10;
  @apply border-white;
  @apply w-full;
  @apply px-2.5;
  @apply bg-base-blue;
  @apply border-b;
}

.active {
  @apply bg-gradient-to-r;
  @apply from-sidebar-left;
  @apply to-sidebar-right;
}
