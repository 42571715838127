/* base */
.base {
  @apply focus:outline-none;
  letter-spacing: 1px;
  border-radius: 3px;
  @apply text-base;
}

.rounded15 {
  @apply focus:outline-none;
  letter-spacing: 1px;
  border-radius: 15px;
  @apply text-base;
}

.rounded20 {
  @apply focus:outline-none;
  letter-spacing: 1px;
  border-radius: 20px;
  @apply text-base;
}

.base:disabled,
.rounded15:disabled,
.rounded20:disabled {
  @apply bg-dark-gray;
  @apply text-white;
  @apply border-0;
}

/* type */
.primary {
  @apply bg-blue;
  @apply text-white;
}

.primary:hover {
  @apply border;
  @apply bg-white;
  @apply border-blue;
  @apply text-blue;
}

.primary:active {
  @apply text-white;
  @apply bg-blue-active;
}

.secondary {
  @apply border;
  @apply border-blue;
  @apply text-blue;
}

.secondary:hover {
  @apply bg-blue;
  @apply text-white;
}

.secondary:active {
  @apply bg-white;
  @apply border-blue-active;
  @apply text-blue-active;
}

.selecting {
  @apply bg-blue-active;
  @apply text-white;
}

.danger {
  @apply bg-red;
  @apply text-white;
}

/* hover時も色が変わらないように:disabled:hoverを追加 */
.base:disabled:hover,
.rounded15:disabled:hover,
.rounded20:disabled:hover {
  @apply bg-dark-gray;
  @apply text-white;
  @apply border-0;
}

/* size */
.xs {
  width: 120px;
  height: 40px;
}

.s {
  width: 120px;
  height: 50px;
}

.m {
  width: 150px;
  height: 50px;
}

.l {
  width: 220px;
  height: 26px;
}

.ll {
  width: 261px;
  height: 40px;
}

.xl {
  width: 300px;
  height: 38px;
}

.xxl {
  width: 400px;
  height: 38px;
}

.csvBtnSize {
  width: 167px;
  height: 30px;
}

.csvBtnStyle {
  border-radius: 15px;
}

.auto {
  width: auto;
  height: 46px;
  min-width: 120px;
}

.custom {
  /* class側でサイズを定義 */
}
