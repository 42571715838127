.button {
  width: 8px;
  height: 8px;
  @apply border-none;
}

.trash {
  color: #979797;
}

.trash:hover {
  stroke: rgba(0, 160, 232, 1);
}

.trash:active {
  stroke: rgba(0, 160, 232, 1);
}
