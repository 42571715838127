.react-autosuggest__container {
  @apply relative;
}

.react-autosuggest__input {
  @apply w-full;
  @apply h-12;
  @apply pl-4;
  @apply tracking-wider;
  @apply border;
  @apply rounded;
}

.react-autosuggest__suggestions-container--open {
  @apply block;
  @apply absolute;
  @apply top-12;
  @apply w-full;
  @apply border-2;
  @apply divide-black;
  @apply bg-white;
  @apply text-base;
  @apply z-10;
}

.react-autosuggest__suggestions-list {
  @apply m-0;
  @apply p-0;
  @apply list-none;
}

.react-autosuggest__suggestion {
  @apply cursor-pointer;
  @apply pl-4;
  @apply py-2;
}

.react-autosuggest__suggestion--highlighted {
  // background-color: #ddd;
  @apply bg-gray-200;
}
